<template>
  <BaseUser
    v-if="user"
    :user="user"
    :title="user.name"
    :sub-text="getUserSubtitle(user)"
    :max-text-length="minLength ? 20 : 30"
    :max-title-length="minLength ? 15 : 24"
    :hide-cursor="user.id == streamUser.id ? true : false"
  >
    <template #image>
      <ChatImage size="sm" :show-online="true" :item="user"> </ChatImage>
    </template>
    <template #options>
      <div
        v-if="channel && !channel.data.direct_messaging"
        class="flex items-center"
        :class="hasPermissions ? 'space-x-1' : 'space-x-2'"
      >
        <div
          v-if="channel.data.channel_admins && channel.data.channel_admins.includes(user.id)"
          class="text-xs font-medium text-gray-400"
        >
          Admin
        </div>
        <div v-else-if="client.user.id != user.id" class="text-xs font-medium text-gray-400">Member</div>
        <DropdownTableCell
          v-if="hasPermissions && client.user.id != user.id && !user.deactivated_at && !channel.data.broadcast_channel"
          :items="filteredDropDownOptions"
          :cell-info="user"
          :global-emit="false"
          @remove-from-chat="$emit('leave-chat', $event)"
          @toggle-admin="$emit('toggle-admin', $event)"
          @send-dm="$emitter.$emit('send-dm', $event)"
        />
        <div
          class="cursor-pointer"
          v-else-if="client.user.id != user.id && !user.deactivated_at && !channel.data.direct_messaging"
          @click="$emitter.$emit('show:user-bio', user)"
        >
          <InformationCircleIcon class="h-3 w-3 text-gray-400 hover:text-gray-300"></InformationCircleIcon>
        </div>
        <div v-else class="w-4"></div>
      </div>
    </template>
  </BaseUser>
</template>
<script setup>
import { computed } from 'vue';
import ChatImage from './ChatImage.vue';
import { InformationCircleIcon } from '@heroicons/vue/20/solid';
import BaseUser from './ChatBaseUser.vue';
import DropdownTableCell from '../dropdown/DropdownTableCell.vue';
import useEmitter from '@/composeables/emitter';
import { useMapGetter } from '@/store/map-state';
import { getUserSubtitle } from '@/composeables/chat';

const { client, streamUser } = useMapGetter();
const $emitter = useEmitter();
const $emit = defineEmits(['leave-chat', 'toggle-admin']);

const props = defineProps({
  hasPermissions: { type: Boolean, default: false },
  user: Object,
  channel: Object
});

// DROPDOWN OPTIONS
const dropDownOptions = computed(() => {
  if (props.channel.data.channel_admins && props.channel.data.channel_admins.includes(props.user.id)) {
    return [
      {
        id: 'makeAdmin',
        html: '<div class="flex space-x-1 text-gray-300 hover:text-white"><svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M15.707 4.293a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L10 8.586l4.293-4.293a1 1 0 011.414 0zm0 6a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L10 14.586l4.293-4.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg><span>Demote to Member</span></div>',
        event: 'toggle-admin'
      },
      {
        id: 'sendDM',
        html: '<div class="flex space-x-1 text-gray-300 hover:text-white"><svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd" /></svg><span>Send Direct Message</span></div>',
        event: 'send-dm'
      },
      {
        id: 'removeFromChat',
        html: '<div class="flex space-x-1 text-gray-300 hover:text-white"> <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor"><path d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z" /></svg><span>Remove</span></div>',
        event: 'remove-from-chat'
      }
    ];
  } else {
    return [
      {
        id: 'makeAdmin',
        html: '<div class="flex space-x-1 text-gray-300 hover:text-white"><svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M5 11l7-7 7 7M5 19l7-7 7 7" /></svg><span>Make Admin</span></div>',
        event: 'toggle-admin'
      },
      {
        id: 'sendDM',
        html: '<div class="flex space-x-1 text-gray-300 hover:text-white"><svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd" /></svg><span>Send Direct Message</span></div>',
        event: 'send-dm'
      },
      {
        id: 'removeFromChat',
        html: '<div class="flex space-x-1 text-gray-300 hover:text-white"> <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor"><path d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z" /></svg><span>Remove</span></div>',
        event: 'remove-from-chat'
      }
    ];
  }
});
const filteredDropDownOptions = computed(() => {
  let channelMember = Object.values(props.channel.state.members).find(x => x.user_id == props.user.id);
  if (
    channelMember &&
    channelMember.invited &&
    !channelMember.invite_accepted_at &&
    !channelMember.invited_rejected_at
  ) {
    let options = dropDownOptions.value.filter(x => x.id !== 'makeAdmin');
    return options;
  }
  return dropDownOptions.value;
});

// OTHERS
const minLength = computed(() => {
  if (
    !props.channel.data.direct_messaging &&
    (props.user.id != streamUser.value.id ||
      (props.channel.data.channel_admins && props.channel.data.channel_admins.includes(props.user.id)))
  ) {
    return true;
  } else {
    return false;
  }
});
</script>
